const Countries = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
      p-id="6700" >
      <path d="M512.131556 314.869476c-150.561151 0-292.439877-43.280576-389.393628-118.791367a9.866393 9.866393 0 1 1 12.102775-15.5889c93.533402 72.879753 231.070915 114.647482 377.290853 114.647482s283.428571-41.767729 377.159301-114.647482A9.866393 9.866393 0 1 1 901.196304 196.078109c-96.953751 75.510791-238.832477 118.791367-389.064748 118.791367zM895.342245 839.498458a9.669065 9.669065 0 0 1-6.051388-2.10483c-93.533402-72.813977-231.005139-114.581706-377.159301-114.581706s-283.560123 41.767729-377.290853 114.581706a9.866393 9.866393 0 1 1-12.102775-15.523124c97.151079-75.510791 239.029805-118.791367 389.393628-118.791367s292.308325 43.280576 389.262076 118.791367a9.800617 9.800617 0 0 1 1.775951 13.812949 9.932169 9.932169 0 0 1-7.827338 3.815005z" p-id="6701"></path>
      <path d="M506.5406 1021.368962a10.06372 10.06372 0 0 1-5.393628-1.578623c-110.569373-71.761562-239.75334-247.778006-239.75334-507.790339 0-199.564234 114.647482-441.751285 240.542652-508.053443a9.734841 9.734841 0 0 1 13.15519 4.143885 9.800617 9.800617 0 0 1-4.078109 13.15519c-118.396711 62.289825-230.215827 300.727646-230.215827 490.622816 0 170.030832 71.366906 388.078109 230.742035 491.2148a9.866393 9.866393 0 0 1-5.327852 18.154162z" p-id="6702"></path>
      <path d="M527.588905 1021.368962a9.866393 9.866393 0 0 1-4.604317-18.614594c108.596095-57.225077 230.215827-279.218911 230.215827-490.754368 0-169.899281-71.366906-387.683453-230.807811-491.083248A9.866393 9.866393 0 1 1 532.850981 4.406989c110.569373 71.695786 239.75334 247.71223 239.75334 507.593011 0 199.695786-114.647482 441.882837-240.542652 508.184995a9.603289 9.603289 0 0 1-4.472764 1.183967z" p-id="6703"></path><path d="M1011.502574 521.866393H12.694763a9.866393 9.866393 0 0 1 0-19.732786h998.807811a9.866393 9.866393 0 0 1 0 19.732786z" p-id="6704"></path>
      <path d="M512.000004 1021.368962a9.866393 9.866393 0 0 1-9.866392-9.866393V12.694758a9.866393 9.866393 0 0 1 19.732785 0v998.807811a9.866393 9.866393 0 0 1-9.866393 9.866393z" p-id="6705"></path>
      <path d="M512.000004 1024a512 512 0 1 1 512-512 512.591984 512.591984 0 0 1-512 512zM512.000004 19.798561a492.267215 492.267215 0 1 0 492.267215 492.201439A492.793422 492.793422 0 0 0 512.000004 19.798561z" p-id="6706"></path>
    </svg>
  )
}

export default Countries
